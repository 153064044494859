import { default as logsiPWtuyr4axMeta } from "/usr/app/pages/admin/logs.vue?macro=true";
import { default as serviceproviderHza3wZ3PzAMeta } from "/usr/app/pages/admin/serviceprovider.vue?macro=true";
import { default as settingsp3J0y0H6tyMeta } from "/usr/app/pages/admin/settings.vue?macro=true";
import { default as indexnU2OsIgFR4Meta } from "/usr/app/pages/client/[clientId]/change-history/index.vue?macro=true";
import { default as searchtO5wk1o8CLMeta } from "/usr/app/pages/client/[clientId]/change-history/search.vue?macro=true";
import { default as indexyTG7VmPihZMeta } from "/usr/app/pages/client/[clientId]/dashboard/index.vue?macro=true";
import { default as explorerWIwGH9JQFGMeta } from "/usr/app/pages/client/[clientId]/explorer.vue?macro=true";
import { default as ioa6CsKFUaqBPMeta } from "/usr/app/pages/client/[clientId]/ioa.vue?macro=true";
import { default as _91_46_46_46slug_93KT1J7GMrHqMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/[...slug].vue?macro=true";
import { default as gpo_45kerberos_45armoring_45supportedEKnACUbdrlMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/gpo-kerberos-armoring-supported.vue?macro=true";
import { default as gpo_45lanmanager_45enabledA1s3hoceE6Meta } from "/usr/app/pages/client/[clientId]/ioe/findings/gpo-lanmanager-enabled.vue?macro=true";
import { default as gpo_45powershell_45logging_45disabledbl9VAX25WFMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/gpo-powershell-logging-disabled.vue?macro=true";
import { default as gpo_45reg_45autologinUwEmtfbka8Meta } from "/usr/app/pages/client/[clientId]/ioe/findings/gpo-reg-autologin.vue?macro=true";
import { default as gpo_45weak_45password_45policyvoDxUjgbZaMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/gpo-weak-password-policy.vue?macro=true";
import { default as group_45rodc_45not_45empty6eihaqKNgFMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/group-rodc-not-empty.vue?macro=true";
import { default as object_45duplicated_45samaccountnameVE3jsLoWVMMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/object-duplicated-samaccountname.vue?macro=true";
import { default as password_45like_45string_45foundALkf4lftttMeta } from "/usr/app/pages/client/[clientId]/ioe/findings/password-like-string-found.vue?macro=true";
import { default as indexoNztaKJwgnMeta } from "/usr/app/pages/client/[clientId]/ioe/index.vue?macro=true";
import { default as security_45logCYpVXyvN9IMeta } from "/usr/app/pages/client/[clientId]/security-log.vue?macro=true";
import { default as indexs12QgJiWn7Meta } from "/usr/app/pages/client/[clientId]/settings/index.vue?macro=true";
import { default as criticalitiesDY006CXFnuMeta } from "/usr/app/pages/client/[clientId]/settings/ioe/criticalities.vue?macro=true";
import { default as thresholds7oxlNPcOBfMeta } from "/usr/app/pages/client/[clientId]/settings/ioe/thresholds.vue?macro=true";
import { default as env_45mismatchmigDMy0U98Meta } from "/usr/app/pages/env-mismatch.vue?macro=true";
import { default as indexfzSMqasWUtMeta } from "/usr/app/pages/index.vue?macro=true";
import { default as installerTnCr8QA2ZmMeta } from "/usr/app/pages/meta/quickcheck/installer.vue?macro=true";
import { default as auditUVA01hBqw9Meta } from "/usr/app/pages/provider/[providerId]/audit.vue?macro=true";
import { default as monitoringSCYmraRdm3Meta } from "/usr/app/pages/provider/[providerId]/monitoring.vue?macro=true";
import { default as quickcheckvYNe4AXHSdMeta } from "/usr/app/pages/provider/[providerId]/quickcheck.vue?macro=true";
import { default as indexnncNiIwQ1vMeta } from "/usr/app/pages/provider/[providerId]/settings/index.vue?macro=true";
import { default as registerikXi2A5cLhMeta } from "/usr/app/pages/register.vue?macro=true";
import { default as settingsyouw32Dx0hMeta } from "/usr/app/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-logs",
    path: "/admin/logs",
    meta: logsiPWtuyr4axMeta || {},
    component: () => import("/usr/app/pages/admin/logs.vue")
  },
  {
    name: "admin-serviceprovider",
    path: "/admin/serviceprovider",
    meta: serviceproviderHza3wZ3PzAMeta || {},
    component: () => import("/usr/app/pages/admin/serviceprovider.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: settingsp3J0y0H6tyMeta || {},
    component: () => import("/usr/app/pages/admin/settings.vue")
  },
  {
    name: "client-clientId-change-history",
    path: "/client/:clientId()/change-history",
    component: () => import("/usr/app/pages/client/[clientId]/change-history/index.vue")
  },
  {
    name: "client-clientId-change-history-search",
    path: "/client/:clientId()/change-history/search",
    component: () => import("/usr/app/pages/client/[clientId]/change-history/search.vue")
  },
  {
    name: "client-clientId-dashboard",
    path: "/client/:clientId()/dashboard",
    component: () => import("/usr/app/pages/client/[clientId]/dashboard/index.vue")
  },
  {
    name: "client-clientId-explorer",
    path: "/client/:clientId()/explorer",
    component: () => import("/usr/app/pages/client/[clientId]/explorer.vue")
  },
  {
    name: "client-clientId-ioa",
    path: "/client/:clientId()/ioa",
    component: () => import("/usr/app/pages/client/[clientId]/ioa.vue")
  },
  {
    name: "client-clientId-ioe-findings-slug",
    path: "/client/:clientId()/ioe/findings/:slug(.*)*",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/[...slug].vue")
  },
  {
    name: "client-clientId-ioe-findings-gpo-kerberos-armoring-supported",
    path: "/client/:clientId()/ioe/findings/gpo-kerberos-armoring-supported",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/gpo-kerberos-armoring-supported.vue")
  },
  {
    name: "client-clientId-ioe-findings-gpo-lanmanager-enabled",
    path: "/client/:clientId()/ioe/findings/gpo-lanmanager-enabled",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/gpo-lanmanager-enabled.vue")
  },
  {
    name: "client-clientId-ioe-findings-gpo-powershell-logging-disabled",
    path: "/client/:clientId()/ioe/findings/gpo-powershell-logging-disabled",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/gpo-powershell-logging-disabled.vue")
  },
  {
    name: "client-clientId-ioe-findings-gpo-reg-autologin",
    path: "/client/:clientId()/ioe/findings/gpo-reg-autologin",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/gpo-reg-autologin.vue")
  },
  {
    name: "client-clientId-ioe-findings-gpo-weak-password-policy",
    path: "/client/:clientId()/ioe/findings/gpo-weak-password-policy",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/gpo-weak-password-policy.vue")
  },
  {
    name: "client-clientId-ioe-findings-group-rodc-not-empty",
    path: "/client/:clientId()/ioe/findings/group-rodc-not-empty",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/group-rodc-not-empty.vue")
  },
  {
    name: "client-clientId-ioe-findings-object-duplicated-samaccountname",
    path: "/client/:clientId()/ioe/findings/object-duplicated-samaccountname",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/object-duplicated-samaccountname.vue")
  },
  {
    name: "client-clientId-ioe-findings-password-like-string-found",
    path: "/client/:clientId()/ioe/findings/password-like-string-found",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/findings/password-like-string-found.vue")
  },
  {
    name: "client-clientId-ioe",
    path: "/client/:clientId()/ioe",
    component: () => import("/usr/app/pages/client/[clientId]/ioe/index.vue")
  },
  {
    name: "client-clientId-security-log",
    path: "/client/:clientId()/security-log",
    component: () => import("/usr/app/pages/client/[clientId]/security-log.vue")
  },
  {
    name: "client-clientId-settings",
    path: "/client/:clientId()/settings",
    component: () => import("/usr/app/pages/client/[clientId]/settings/index.vue")
  },
  {
    name: "client-clientId-settings-ioe-criticalities",
    path: "/client/:clientId()/settings/ioe/criticalities",
    component: () => import("/usr/app/pages/client/[clientId]/settings/ioe/criticalities.vue")
  },
  {
    name: "client-clientId-settings-ioe-thresholds",
    path: "/client/:clientId()/settings/ioe/thresholds",
    component: () => import("/usr/app/pages/client/[clientId]/settings/ioe/thresholds.vue")
  },
  {
    name: "env-mismatch",
    path: "/env-mismatch",
    meta: env_45mismatchmigDMy0U98Meta || {},
    component: () => import("/usr/app/pages/env-mismatch.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/app/pages/index.vue")
  },
  {
    name: "meta-quickcheck-installer",
    path: "/meta/quickcheck/installer",
    meta: installerTnCr8QA2ZmMeta || {},
    component: () => import("/usr/app/pages/meta/quickcheck/installer.vue")
  },
  {
    name: "provider-providerId-audit",
    path: "/provider/:providerId()/audit",
    meta: auditUVA01hBqw9Meta || {},
    component: () => import("/usr/app/pages/provider/[providerId]/audit.vue")
  },
  {
    name: "provider-providerId-monitoring",
    path: "/provider/:providerId()/monitoring",
    meta: monitoringSCYmraRdm3Meta || {},
    component: () => import("/usr/app/pages/provider/[providerId]/monitoring.vue")
  },
  {
    name: "provider-providerId-quickcheck",
    path: "/provider/:providerId()/quickcheck",
    meta: quickcheckvYNe4AXHSdMeta || {},
    component: () => import("/usr/app/pages/provider/[providerId]/quickcheck.vue")
  },
  {
    name: "provider-providerId-settings",
    path: "/provider/:providerId()/settings",
    meta: indexnncNiIwQ1vMeta || {},
    component: () => import("/usr/app/pages/provider/[providerId]/settings/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerikXi2A5cLhMeta || {},
    component: () => import("/usr/app/pages/register.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsyouw32Dx0hMeta || {},
    component: () => import("/usr/app/pages/settings.vue")
  }
]