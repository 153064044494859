<template>
  <div class="h-full">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const { signOut, getSession } = useAuth()
const { $logger, $api, $sentry } = useNuxtApp()

// check if jwt expired
setInterval(() => {
  $api.user.validateSession().then(async (res) => {
    // if response code not 200, then logout
    $logger.info('validateSession: ' + res.status)
    if (res.status !== 200) {
      $logger.warn('Session expired')
      $api.user.endKeycloakSession()
      $sentry.flush()
      await signOut('keycloak')
    }
  }).catch(async (e) => {
    // login failed, try to relogin or logout
    $logger.error('error in validateSession', e)
    const session = await getSession()
    $logger.info(session)
  })
}, 5000)

</script>
